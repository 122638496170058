import React from "react"
import styled from "styled-components"
import ReactMarkdown from 'react-markdown'
import DescriptionBlock from "../Home/DescriptionBlock"

const StyledWrapper = styled.div`
  margin-top: 100px;

  .subtext {
    line-height: normal;
    font-size: ${props => props.theme["FooterLinkSize"]};
    color: ${props => props.theme["FooterLinkSize"]};
    margin-top: 15px;

    @media (max-width: 600px) {
      line-height: 23px;
      font-size: 15px;
    }

    a{
      color: black;
      text-decoration: underline;
    }
  }

  .list-heading {
    font-size: 21px;
    font-weight: ${props => props.theme["headingBold"]};
    letter-spacing: ${props => props.theme["zero"]};
    line-height: 28px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .unorder-list {
    font-weight: ${props => props.theme["textBold"]};
    line-height: 24px;
  }

  .vl {
    border-right: 1.5px solid ${props => props.theme["leftSidebarLineDivider"]};
  }

  .real-rating {
    text-decoration: underline;
    color: black;
  }
`

const appSupportBody = (
  <div>
    <p>We provide end-to-end application support to support the development of commercial awareness & technical knowledge through CV and written application reviews, online test support, mock interviews & assessment centre prep.</p>
    <p>Our experience working with prestigious firms will help you improve your chances of success.</p>
  </div>
)

const info = [
  {
    criteria: [
      { eligibility: "Household income below £42,620." },
      {
        eligibility:
          "Attended UK state schools only (or since age 14 if educated abroad before then).",
      },
      {
        eligibility:
          "Achieved at least BBB in top 3 A-Levels or an equivalent qualification*.",
      },
      { eligibility: "Achieved at least a C in GCSE Maths and English." },
      {
        eligibility:
          "Currently an undergraduate that is in full-time study at a UK university**.",
      },
      {
        eligibility:
          "Should not be studying social work, nursing, dentistry or medicine.",
      },
    ],
  },
  {
    includes: [
      {
        title: "EXCLUSIVE OPPORTUNITIES",
        description:
          "We expand professional networks by connecting students to top employers, providing them with access to exclusive opportunities - professional networking, insight days, work experience weeks, internships and mentors.",
      },
      {
        title: "APPLICATION SUPPORT",
        description: appSupportBody
          ,
      },
      {
        title: "EMPLOYABILITY SKILLS",
        description:
          "Career Academies, Skills Workshops and Video Forums are designed to be both practical and interactive, to support your development of the essential skills you need to succeed in the graduate labour market.  You will be able to track your progress through our Graduate Employability Framework assessment tool, getEmployable.",
      },
      {
        title: "COMMUNITY",
        description:
          "As part of our student community, you will have the chance to meet hundreds of other like-minded students and leverage each other's experiences, networks and skills.",
      },
    ],
  },
]

const EligibilityAndBenefits = ({data}) => {
  return (
    <StyledWrapper>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-5 padding-left-8 padding-right-8 mx-auto">
            <h1 className="mb-4">{data[0].title}</h1>
            <p className="subtext">{data[0].subtitle}</p>
            <ul className="p-0">
              {React.Children.toArray(
                data[0].criteria.map(item => (
                  <ul>
                    <li className="subtext">{item.eligibility} </li>
                  </ul>
                ))
              )}
              </ul>
              <p className="subtext">
                {/* <ReactMarkdown source={data[0].subtext} /> */}
                <DescriptionBlock description={data[0].subtext}/>
                </p>
          </div>
          <div className="vl mx-2 mx-md-5 d-none d-lg-block"></div>
          <div className="col-12 col-md-6 col-lg-5 padding-left-8 padding-right-8 mx-auto">
            <h1 className="mb-4 mt-5 mt-md-0">{data[1].title}</h1>
            <ol className="p-0">
            {data[1].criteria.map(item => (
              <>
                <li className="list-heading mb-2">{item.title}</li>
                <ul className="pl-0 mb-4">
                  {/* <li className="subtext unorder-list"><ReactMarkdown source={item.description} /></li> */}
                  <li className="subtext unorder-list">
                    {/* <ReactMarkdown source={item.description} /> */}
                    <DescriptionBlock description={item.description}/>
                    </li>
                </ul>
                </>
            ))}
            </ol>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default EligibilityAndBenefits
