import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .white-background {
    z-index: 2;
    padding-top: ${props => props.theme["percent-10"]};
    padding-bottom: ${props => props.theme["percent-10"]};
  }

  .hover-background-img {
    position: absolute;
    top: ${props => props.theme["zero"]};
    left: ${props => props.theme["zero"]};
    width: ${props => props.theme["percent-100"]};
    height: ${props => props.theme["percent-100"]};
    object-fit: cover;
    opacity: ${props => props.theme["zero"]};
    z-index: -1;
    filter: brightness(30%);
  }

  .white-background:hover .hover-background-img {
    opacity: 1;
    transition: opacity 0.3s linear;
  }

  .white-background:hover .text-color-change {
    color: ${props => props.theme["secondaryColor"]};
  }

  .white-background:hover .program-cta-links {
    color: ${props => props.theme["secondaryColor"]};
  }

  .high-z-index {
    z-index: 3;
  }

  .program-cta-links {
    color: black;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .program-cta-links p {
    @media (max-width: 600px) {
      line-height: 20px;
    }
  }

  [class^="col"] {
    display: flex;
    flex-direction: column;
  }

  [class^="col"] div {
    flex-grow: 1;
  }
`;

const HoverEffectBlocks = ({ heading, programs }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid padding-left-8 padding-right-8">
        <h1>{heading && heading.title}</h1>
        <div className="row">
          {programs.length > 0 &&
            programs.map(program => (
              <div className="col-12 col-md-6 col-lg-4 mt-2 ">
                <div className="col-12 white-background padding-left-8">
                  <div className="high-z-index ">
                    <h1 className="text-color-change">
                      {program && program.title}
                    </h1>
                    <p className="subtext text-color-change">
                      {program && program.description}
                    </p>
                    {program.Cta != null &&
                      program.Cta.length > 0 &&
                      program.Cta.map(item => (
                        <Link
                          className="program-cta-links"
                          target="_blank"
                          to={item.link}
                        >
                          <p className="mb-2">{item.title}</p>
                        </Link>
                      ))}
                  </div>
                  {program.image && (
                    <img
                      className="hover-background-img"
                      src={mainUrl + program.image.url}
                      alt=""
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default HoverEffectBlocks;
