import React, { useEffect, useState } from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import axios from "axios";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import FlatButton from "../components/Reusables/FlatButton";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import UniversityStudents from "../components/Students/UniversityStudents";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../components/Students/EligibilityAndBenefits";
import Accordion from "../components/Reusables/Accordion";
import StatisticBox from "../components/Home/StatisticBlock";

import events1 from "../images/pageImages/Students/Events.png";
import hero from "../images/pageImages/Investment/Hero.jpg";
import Testimonial1 from "../images/pageImages/Investment/Student1.jpg";
import Testimonial2 from "../images/pageImages/Investment/Student2.jpg";
import Testimonial3 from "../images/pageImages/Investment/Student3.jpg";
import rise from "../images/pageImages/Students/Rise.jpg";
import aim from "../images/pageImages/Students/Aim.jpg";
import involve from "../images/pageImages/Investment/involve.png";
import diversitylogo from "../images/pageImages/Investment/diversityprojectlogo.png";
import applyphoto from "../images/pageImages/Investment/apply.png";
import ReactMarkdown from "react-markdown";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import DescriptionBlock from "../components/Home/DescriptionBlock"
const StyledWrapper = styled.div`
  .apply-now {
    padding: 3% 0;
    background-color: #e43d5a;
  }

  .boxHeight {
    @media (max-width: 600px) {
      height: 250px;
    }
  }

  .accordion-start {
    padding-top: 50px;
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .flat-btn-link:hover {
    color: white;
  }

  .section-divider {
    margin-top: 50px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .grew-up {
    padding: 3% 0;
    background-color: #5db9db;
    height: 160px;

    @media (max-width: 600px) {
      height: unset;
      padding: 5% 8%;
    }
  }

  .onhover-display {
    color: white;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    display: none;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .grew-up:hover {
    padding-top: 2%;
  }

  .grew-up:hover .onhover-display {
    display: block;
  }

  .grew-up:hover .flat-button {
    display: none;

    @media (max-width: 600px) {
      display: unset;
    }
  }

  .onhover-links {
    color: white;
  }
  .onhover-links:hover {
    color: white;
  }
  .eng-spring .subtext{
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const accordion = [
  {
    title: "What is the Investment Industry Springboard?",
    description: (
      <div>
        <p>
          The Investment Industry Springboard is a structured programme of
          mentoring, work experience, and career events - all designed to help
          you secure internships and jobs in the investment industry.
        </p>
        <li>
          <b>1-to-1 professional mentoring</b> from an expert working in the
          sector, who can support you with your CV and job applications{" "}
        </li>
        <li>
          <b>Opportunity for hands-on work experience</b> at leading investment
          firms, to gain an in-depth insight into the industry{" "}
        </li>
        <li>
          <b>Exclusive career events</b> to help you build your network and
          knowledge of investments, with travel and accommodation costs covered
        </li>
        <p>
          Launched in 2019, the programme supported 100 undergraduates in its
          inaugural year and will now support an additional 85 in 20/21. The
          programme will help these students to gain the knowledge, skills and
          experience needed to make more competitive applications to spring
          insights, internships and graduate jobs in the investment industry.
        </p>
        <p>
          The programme is possible because of our partnership with The
          Diversity Project, a cross-company initiative championing a more
          inclusive investments and savings sector.
        </p>
      </div>
    ),
  },
  {
    title: "What's does the Springboard programme involve?",
    description: (
      <div>
        <p>
          At the start of the programme, every undergraduate (known as upReach
          "Associates") is matched with a dedicated professional mentor, who is
          already working in investments and savings.{" "}
        </p>
        <p>
          his mentor will support you with your personal and professional
          development, and, based on their own successful experiences, offer
          tailored advice and guidance on any job applications you're making to
          the industry. Mentors support Associates with:
        </p>
        <li>CV reviews and feedback</li>
        <li>
          Practice job interviews for spring weeks, internships and other career
          opportunities
        </li>
        <li>Practice for assessment centre days</li>
        <li>Goal-setting, and advice on reaching career objectives </li>
        <p>
          Alongside mentoring, in your first year of the programme you will have
          the opportunity to participate in a series of events and work
          experience, designed to introduce you to the investments sector and
          equip you with the skills you need to make competitive job
          applications:
        </p>
        <img className="img-fluid" src={involve} alt="involve"></img>
      </div>
    ),
  },
  {
    title: "Has the Springboard programme been successful?",
    description: (
      <div>
        <p>
          Undergraduates on the Investment Industry Springboard have achieved so
          much since the programme launched in Autumn 2019:
        </p>
        <li>
          <b>
            81% of students are set to complete work experience this year at an
            investment firm
          </b>{" "}
          as a result of participating in the Springboard programme.
        </li>
        <li>
          <b>
            46% of students have already achieved additional career
            opportunities
          </b>{" "}
          since the programme launched in Autumn 2019, by making successful
          applications to spring insights, summer internships and placement
          years, with the help of their mentors.
        </li>
      </div>
    ),
  },
  {
    title: "Who can take part in the Springboard programme?",
    description: (
      <div>
        <p>
          <b>Degree year:</b> We're looking for students entering their 1st, 2nd
          or penultimate year of university in Autumn 2020. We are unable to
          accept new finalist students onto the programme.
        </p>
        <p>
          <b>University:</b> Students from any UK university can sign up, and we
          currently have 37 universities represented on the programme. We will
          ensure that travel costs aren't a barrier to you participating in any
          London events.
        </p>
        <p>
          <b>Degree subject:</b> We encourage students from all different degree
          backgrounds to sign up. There's no need to have studied a Finance or
          Economics degree to take part!
        </p>
        <p>
          <b>Interest in investments:</b> More important than existing knowledge
          is that you have a real interest in the investment industry, and are
          eager to help others invest and save their money.
        </p>
        <p>
          <b>upReach eligibility:</b> Students must fit our general eligibility
          criteria in order to be accepted onto this programme.
        </p>
      </div>
    ),
  },
  {
    title: "How do I apply?",
    description: (
      <div>
        <p>
          <b>
            Applications are now open for the Investment Industry Springboard
            2020 - 2021.
          </b>
        </p>
        <p>
          Please see a summary of the application process below, and register
          online by clicking the image. One of the upReach team will then be in
          touch to let you know how to complete the next steps.
        </p>
        <p>
          Questions before applying? Reach out on StephanieL@upReach.org.uk and
          we can help.
        </p>
        <img className="img-fluid" src={applyphoto} alt="apply"></img>
      </div>
    ),
  },
];

const carousel = [
  {
    description:
      "I just got an offer from BlackRock for a summer internship...which I wouldn't have got without upReach. I have spoken to my mentor, Richard, many times - and he's given a breadth of understanding of the sector and supported me with many mock interviews. This massively improved my confidence and was so important to my success - I can't wait to be part of more upReach events!",
    image: `${Testimonial1}`,
    author: "- Arun, Summer Intern at BlackRock",
  },
  {
    description:
      "My mentor is amazing! My mentor has helped me have the confidence to network with new people. and to talk proudly about my achievements. They have also helped me gain connections in the industry, and allowed me to be informed going through the different stages of my job applications",
    image: `${Testimonial2}`,
    author: "- Glory, Spring Insight Participant at JP Morgan",
  },
  {
    description:
      "I secured an Internship with Willis Towers Watson. I was in regular contact with my mentor throughout the selection process, from mock interviews to informal chats, and it made me feel far more confident and prepared for each stage. I have found that since beginning the mentor scheme, my industry awareness has grown exponentially...ultimately boosting my employability. Mentoring to me has been invaluable.",
    image: `${Testimonial3}`,
    author: "- Matthew, Summer Intern at Willis Towers",
  },
];

const accordionHeading = {
  heading: "",
  include: "",
};

const heading = { title: "Our Programmes" };

const heroImage = { hero: `${hero}` };

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Students />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/investment-spring-board`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};

const Students = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("investmentSpringBoard", fetchData);
  const { data: heroImage } = useQuery("investmentImages", fetchHeroImage);
  const mainUrl = process.env.GATSBY_API_URL;
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "InvestmentPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {/* <SectionDivider /> */}
        {newData &&
          newData.showTestimonial &&
          newData.Testimonial.length > 0 && (
            <Testimonial carousel={newData.Testimonial} />
          )}
        <div className="container-fluid p-0">
          <div className="padding-left-8 padding-right-8 accordion-start">
            {newData && 
            // <ReactMarkdown>{newData.MainHeading}</ReactMarkdown>
            <div className="eng-spring">
              <DescriptionBlock description={newData.MainHeading}/>
              </div>
            }
            {newData && newData.showSponsorImage && newData.sponserImage && (
              <img
                src={mainUrl + newData.sponserImage.url}
                alt="diversitylogo"
              />
            )}
          </div>
          {newData && newData.showAccordion && (
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
          )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};
