import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import ReactGA from 'react-ga'

const StyledWrapper = styled.div`
  .subtext {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    @media (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .uni-links,
  .school-links {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 50px;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 30px;
    }
  }

  .uni-links {
    color: black;
  }

  .school-links {
    color: white;
  }

  .still-in-school {
    color: white;
    background-color: #464fd0;
    padding: 3% 5%;

    @media (max-width: 600px) {
      padding: 10% 5%;
    }
  }

  .university-students {
    color: black;
    padding: 3% 5%;
    background-color: white;

    @media (max-width: 600px) {
      padding: 10% 5%;
    }
  }

  p {
    margin-bottom: 0;
  }
`

const UniversityStudents = ({data}) => {
  const handleApply=()=>{
    ReactGA.event({
      category:"Students Page",
      action: "Apply Now - Click",
    });
  }
  const handleRegister=()=>{
    ReactGA.event({
      category:"Students Page",
      action: "Sixth Former Pre-Register - Click",
    });
  }
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-7 university-students padding-left-8 padding-right-8">
            <h1>{data[0].title}</h1>
            <p className="subtext my-3">
              {data[0].description}
            </p>
            <Link className="uni-links" to={data[0].tag1link}>
              {" "}
              <p onClick={handleApply}> {data[0].tag1} </p>
            </Link>
            <Link
              className="uni-links"
              target="_blank"
              to={data[0].taglink2}
            >
              {" "}
              <p> {data[0].tag2} </p>{" "}
            </Link>
            <Link
              className="uni-links"
              target="_blank"
              to={data[0].taglink3}
            >
              {" "}
              <p> {data[0].tag3} </p>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-5 still-in-school padding-left-8 padding-right-8">
            <h1>{data[1].title}</h1>
            <p className="subtext my-3">
            {data[1].description}{" "}
            </p>
            <Link
              target="_blank"
              className="school-links"
              to={data[1].tag1link}
            >
              <p> {data[1].tag1} </p>
            </Link>
            <Link
              target="_blank"
              className="school-links"
              to={data[1].taglink2}
            >
              <p onClick={handleRegister}> {data[1].tag2} </p>
            </Link>
            <Link className="school-links" to={data[1].taglink3}>
              <p> {data[1].tag3} </p>
            </Link>
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default UniversityStudents
